import { createSlice } from '@reduxjs/toolkit';

const glossarySlice = createSlice({
  name: 'glossary',
  initialState: {},
  reducers: {
    setGlossary: (state, action) => {
      state.glossary = action.payload;
    },
  },
});

export const { setGlossary } = glossarySlice.actions;
export default glossarySlice.reducer;
